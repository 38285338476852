export default class Select2Init {
  constructor() {
    $.fn.select2.defaults.set("theme", "bootstrap4");

    $(document).on('init-select2', '.select2', this.initSelect2);

    this.withTags();
    this.withRemote();
    this.trigger();
  }

  withTags() {
    $(document).on('init-select2', '.select2-tags', function() {
      $(this).select2({
        tags: true,
        width: '100%',
        placeholder: $(this).attr('placeholder')
      });
    });
  }

  withRemote() {
    $(document).on('init-select2', '.select2-remote', function() {
      $(this).select2({
        ajax: {
          url: $(this).attr('data-endpoint'),
          dataType: 'json',
          request: 500,
          placeholder: $(this).attr('placeholder')
        }
      })
    });
  }

  trigger() {
    this.triggerDefault();
    this.triggerTags();
    this.triggerModal();
    this.triggerRemote();
  }

  triggerDefault() {
    $('select.select2').trigger('init-select2');
  }

  triggerTags() {
    $('select.select2-tags').trigger('init-select2');
  }

  triggerRemote() {
    $('select.select2-remote').trigger('init-select2');
  }

  triggerModal() {
    $(document).on('shown.bs.modal', function() {
      $(this).find('select.select2').trigger('init-select2');
      $(this).find('select.select2-tags').trigger('init-select2');
      $('select.select2-remote').trigger('init-select2');
    });
  }

  initSelect2(el) {
    $(el.target).select2({
      width: '100%',
      placeholder: $(this).attr('placeholder')
    });
  }
}
