/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

//jquery
import {} from 'jquery-ujs'
window.jQuery = jQuery
window.$ = $

//bootstrap
require('bootstrap');

import "cocoon-js";

import { AjaxModal } from 'epigenesys-js'

import {} from 'select2'
import Select2Init from '../scripts/select2_init'

$(document).ready( () => {
  new Select2Init();
  $('[data-toggle="popover"]').popover();

  $('.show_button').on('click', function(){
    $('.filters').removeClass('hidden');
  })
});

//fontawesome
import fontawesome from '@fortawesome/fontawesome-free'
// https://fontawesome.com/how-to-use/js-component-packages
import solid from '@fortawesome/free-solid-svg-icons'
